.left {
  background-color: #fff;
  margin: 0px !important;

}

.form-login {
  margin: auto;
  padding: 100px;
  width: 70%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

}

.password-field {
  display: flex;
  flex-direction: column;

}

.password-input-container {
  display: flex;
  align-items: center;
  position: relative;
}

.password-input-container input {
  flex-grow: 1;
}

.password-input-container .password-icon {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
  cursor: pointer;
}

@media screen and (min-width:768px) {
  .right {
    background-color: #FCFCFC;
    min-height: 100vh;
    position: relative;
  }

}

@media screen and (max-width:768px) {
  .form-login {
    margin: auto;
    padding: 20px 20px;
    width: 100%;
    position: relative;
    left: inherit;
    top: inherit;
    transform: inherit;
  }

  h1.title-login {
    display: none;
  }

  img.login-logo {
    width: 125px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  div.login.min-vh-100 {
    height: auto !important;
  }
}

.fa.fa-spinner {
  display: inline-block;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}