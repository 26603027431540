@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
#side_nav {
  background: #fff;
  min-width: 250px;
  max-width: 250px;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Oswald";
}

.content {
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
}
hr.h-color {
  background: #eee;
}

.sidebar a.active {
  background: #eee;
  border-radius: 8px;
}

.sidebar a.active ,
.sidebar li.active a:hover {
  color: #6e7071;
  font-weight: bold;
}

.sidebar li a {
  color: #294991;
}

.navbar-brand {
  background-color: #294991 !;
}

@media (max-width: 767px) {
  #side_nav {
    margin-left: -250px;
    position: absolute;
    height: 100%;
    z-index: 1;
  }

  #side_nav.active {
    margin-left: 0;
  }

  .mobVersion .btn-secondary-al{
    padding: 5px 15px !important;
  }
  .navbar.fixed-top{
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); 
    padding: 0px ;
  }
  button, a{
    padding: 0px !important;
  }
} 
.mobVersion .btn-secondary-al{
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.navbar.fixed-top {
  position: sticky;
  top: 0;
  z-index: 1030; 
  background-color: #f8f9fa;
}

.mobVersion {
  padding:0px;
}
.btn-secondary-icon,#pop-up{
  background: transparent !important;
  color: #294991;
  border: none;
}
