.list-alertes{
    border-radius: 10px;
    border: 1px solid #E8E8E7;
    background:  #FFF;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05);  
    padding: 10px;
    
}
.police-alerte{
    border-radius: 10px;
    border: 1px solid #E8E8E7;
    background:  #FFF;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05);    
}
.police-alerte img{
   width: -webkit-fill-available;
}
.link{
    color: var(--typo-color);
    font-family: 'DM-Sans-Regular';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;  
    margin: 13px !important;
}
.status-generated{
    border-radius: 20px;
    background-color: #FFE8D0;
    padding: 10px;
    color:#FF8A15;
    text-align: center;
    font-family: 'DM-Sans-Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.status-sended{
    border-radius: 20px;
    background-color: #DFF5DC;
    padding: 10px;
    color:#5FCC4E;
    text-align: center;
    font-family: 'DM-Sans-Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.contentTitle{
    color: #294991;
}
.contentDescription{
    border-bottom: 1px solid #6e7071;

}
#rapport{
    overflow: hidden !important;

}