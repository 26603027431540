.bg-color{
    background-color: var(--bg-color);
    border-radius: 20px 0px 0px 20px;
    border: 0px 0px 0px 0.4px;
}
@media only screen and (max-width: 600px) {
    .hideToMobile{
        display: none;
    }
    .showToMobile{
      display: block !important;
    }
  }
  .showToMobile{
    display: none;
  }

  /* Loader.css */
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    opacity: 0.8;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #213e83;
    animation: spin 1s ease infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
