.mt-48{
    margin-top: 48px !important;
}
.mt-42{
    margin-top: 42px !important;
}
.mt-33{
    margin-top:33px !important;
}
.float-right{
    float: right;
}
.mt-38{
    margin-top:38px !important;
}
.mt-24{
    margin-top:24px !important;
}
.mt-20{
    margin-top:20px !important;
}
.mt-12{
    margin-top:12px !important;
}
.rdt_Pagination{
    background-color: var(--bg-color) !important;
}
a{
    display: inline-block !important;
}
.css-qbdosj-Input input, input#react-select-5-input, input#react-select-3-input{
  border: none !important;

}
#search {
    padding: 9px 10px;
    background: url(../icons/clarity_search-line.svg) right center;
    padding: 16px 18px 16px 50px;
    background-position: 18px;
    background-color: #fff;
    background-repeat: no-repeat;
  }
  .bg-primary-al header{
    background-color:var(--bg-color) !important;
    padding:0 !important;
  }
  .bread-item{
    color: var(--typo-color);
    font-family: 'DM-Sans-Medium';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
   
  }
  .bread-crumb{
     margin-bottom: 26px;
  }
  .retour-link{
    color: #2a4b9c;
    font-family: 'DM-Sans-SemiBold';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
    padding-left: 4px;
    margin-bottom: 42px;
  }
  .subTitle{
    color: var(--typo-color);
    /* Subtitle */
    font-family: 'DM-Sans-SemiBold';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    }
    label{
        color: var(--typo-color);
        leading-trim: both;
        text-edge: cap;
        font-family: 'DM-Sans-Regular';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-bottom: 10px;
    }
  input[type="text"], [type="search"]{
    border-radius: 8px;
    border: 1.5px solid #E8E8E7 !important;
  }
  input:hover{
    border-radius: 8px;
    border: 1.5px solid #2a4b9c !important;
    box-shadow: none !important;
  }
  input:focus{
    border-radius: 8px;
    border: 1.5px solid #2a4b9c !important;
    box-shadow: none !important;
  }
  input::placeholder{
    color: #777371 !important;
  /* Current text */
  font-family: 'DM-Sans-Regular'  !important;
  font-size: 16px   !important;
  font-style: normal   !important;
  font-weight: 400   !important;
  line-height: 22px   !important; /* 137.5% */
  }
  .datatable-all{
    border: 0.5px solid #DEDEDE;
    border-radius: 10px !important;
  }
  
  .react-datepicker-wrapper{
    width:100%;
  }
  .iWyFZZ div:first-child{
    white-space: break-spaces !important;
  }
  .modified-txt{
    /* text-transform: capitalize; */
  }
  @media screen and (max-width:1024px){
    .icon-title{
    width: 8% !important;
    }
    button, a{
      padding: 5px !important;
    }
  }
  
  .iconButon{
    background: transparent;
    border: none;
  }