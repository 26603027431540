.container-al {
  padding: 67px 42px;

}


.tabs-container {
  display: flex;
}

.tab {
  padding: 15px;
  text-align: center;
  cursor: pointer;
  position: relative;
  font-size: 18px;
  /* font-weight: 600; */
  font-family: 'DM-Sans-Medium';
  color: var(--typo-color)
}

.underline {
  height: 2px;
  width: 0;
  background-color: #2a4a98;
  /* Set your desired underline color */
  position: absolute;
  bottom: 0;
  transition: width 0.3s ease;
}

.tab.active {
  border-bottom: 5px solid #2a4a98;
  padding-bottom: 10px;
}

.fa.fa-check-circle-o {
  font-size: 6rem;
  color: #5FCC4E;
}

.fa.fa-times {
  font-size: 6em;
  color: #F14B26;
}

.priority-Faible,
.status-Faible {
  border-radius: 20px;
  background-color: #DFF5DC;
  padding: 7px 15px;
  color: #5FCC4E;
  text-align: center;
  font-family: 'DM-Sans-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: block;
}

.priority-Moyenne,
.status-normal {
  border-radius: 20px;
  background-color: #FFE8D0;
  padding: 7px 15px;
  color: #FF8A15;
  text-align: center;
  font-family: 'DM-Sans-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: block;
}

.priority-Elevée,
.status-haute {
  border-radius: 20px;
  background-color: #FCDBD4;
  padding: 7px 15px;
  color: #F14B26;
  text-align: center;
  font-family: 'DM-Sans-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: block;
}

/* drag and drop style component */
.dropzone {
  border: 2px dashed #2a4a98;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.dropzone.active {
  border-color: var(--primary-color-press);
}

/* Card style */
.card {
  background-color: #fff;
  color: #fff !important;
  border-radius: 10px;
  border: none;
  position: relative;
  margin-bottom: 30px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1), 0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12), 0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
}

.l-bg-cherry {
  background: linear-gradient(to right, #32417f, #636264) !important;
}
.l-bg-cherry:hover {
  background: linear-gradient(to right, #636264,#32417f) !important;
  transition: 0.7s cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 0.8;
  cursor: pointer;
}

.l-bg-blue-dark {
  background: linear-gradient(to right, #373b44, #213e83) !important;
}
.l-bg-blue-dark:hover {
  background: linear-gradient(to right, #213e83,#373b44) !important;
  transition: 0.7s cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 0.8;
  cursor: pointer;
}

.l-bg-green-dark {
  background: linear-gradient(to right, #636264, #373b44) !important;
}
.l-bg-green-dark:hover {
  background: linear-gradient(to right,#373b44, #636264) !important;
  transition: 0.7s cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 0.8;
  cursor: pointer;
}

.l-bg-orange-dark {
  background: linear-gradient(to right, #213e83, #373b44) !important;
}
.l-bg-orange-dark {
  background: linear-gradient(to right,#373b44, #213e83) !important;
  transition: 0.7s cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 0.8;
  cursor: pointer;
}

.card .card-statistic-3 .card-icon-large .fa,
.card .card-statistic-3 .card-icon-large .fa,
.card .card-statistic-3 .card-icon-large .fa,
.card .card-statistic-3 .card-icon-large .fal {
  font-size: 110px;
}

.card .card-statistic-3 .card-icon {  
  text-align: center;
  line-height: 50px;
  margin-left: 15px;
  color: #000;
  position: absolute;
  right: -5px;
  top: 20px;
  opacity: 0.1;
}

.l-bg-cyan {
  background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
  color: #fff;
}

.l-bg-green {
  background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%) !important;
  color: #fff;
}

.l-bg-orange {
  background: linear-gradient(to right, #f9900e, #ffba56) !important;
  color: #fff;
}

.l-bg-cyan {
  background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
  color: #fff;
}

.homeTitle h1 {
  position: relative;
  padding: 0;
  margin: 0;
  color: #080808;
  text-align: center !important;
  text-transform: uppercase;
}

.homeTitle h1:before {
  width: 28px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 6px;
  left: 50%;
  margin-left: -14px;
  background-color: #213e83;
}
.homeTitle h1:after {
  width: 100px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  left: 50%;
  margin-left: -50px;
  background-color: #213e83;
  top: -5px;
}

.factDocBg{
  background: #6e7071;
  Color:#213e83 !important
} 

@media (max-width: 767px) {
  .container-al {
    padding: 15px 10px;
  }
  .homeTitle h1{
    font-size: 16px !important;
  }

  .homeTitle h1:after{
    top: -13px;;
  }
  .homeTitle h1:before{
    bottom: 14px;
  }
  .detailCard .card-title h2.detailTilte{
    font-size: 18px !important;
    margin-bottom: 0px !important;
  }
  .detailCard .card-title span.mb-3{
    font-size: 14px !important;
  }

  .factDocBg .card-body{
      padding-top: 6px !important;
      padding: 8px;
  }
  .factDocBg{
    border-radius: 4px !important;
    box-shadow: none !important;
    background-color: #F1f2F4;
    border-radius: 1px !important;
  }
  .factDocBg .detailCardInfon{
    font-size: 12px !important;
  }
  .factDocBg .detailCardInfon span{
    padding: 2px 12px !important;
    border-radius: 2px;
    background: #f0eded;
    font-size: 12px !important;
  }
  .detailCardInfon{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 4px;
  }
}
/* detail View */
.detailCard{
  background-color: #FCDBD4;
  color: #000 !important;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}
.detailCard label{
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
  font-size: 18px;
}
.detailCard .card-title h2{
  font-size: 34px !important;
  margin-bottom: 5px !important;
  line-height: 26px !important;

}
.detailCard .card-title span.mb-3{
  font-size: 16px !important;
}
.showToMobile label.contentTitle{
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
  
}

h2.detailTilte{
  margin-bottom: -6px !important;
}
.showToMobile .description,.mobDetail{
  color: #6e6f71;
  
}
@media (max-width: 767px) {
  .showToMobile .description,.mobDetail{
    color: #6e6f71;
    font-size: 13px !important;
  }
  .contentTitle{
    padding-bottom: 0px !important;
  }
  h2.detailMobTile{
    font-size: 20 px !important;
   
  }
}